import styled from 'styled-components'

export const Blockquote2 = styled.blockquote`
  padding: 10px 30px 10px 30px;
  margin: 30px 0;
  border-radius: 3px;
  border-left: 4px solid rgb(255 98 99);
  background: rgb(255 243 243);
  color: rgb(170 47 47);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 22px;
    margin: 15px 0;
  }

  p {
    margin: 5px 0 10px;
  }
`
