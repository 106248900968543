import styled from 'styled-components'

export const InlineCode = styled.code`
  margin: 0 3px;
  padding: 1px 4px;
  border-radius: 3px;
  background: ${p => p.theme.colors.grayExtraLight};
  font-size: 14px;
  color: #4263F7;
`
