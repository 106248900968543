export const imports = {
  'LICENSE.md': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "license" */ 'LICENSE.md'
    ),
  'src/docs/documentation/2-widget.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-2-widget" */ 'src/docs/documentation/2-widget.mdx'
    ),
  'src/docs/documentation/3-methods.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-3-methods" */ 'src/docs/documentation/3-methods.mdx'
    ),
  'src/docs/documentation/4-usage.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-4-usage" */ 'src/docs/documentation/4-usage.mdx'
    ),
  'src/docs/documentation/5-scenarios.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-5-scenarios" */ 'src/docs/documentation/5-scenarios.mdx'
    ),
  'src/docs/documentation/6-reference.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-6-reference" */ 'src/docs/documentation/6-reference.mdx'
    ),
  'src/docs/documentation/7-extension.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-7-extension" */ 'src/docs/documentation/7-extension.mdx'
    ),
  'src/docs/documentation/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-index" */ 'src/docs/documentation/index.mdx'
    ),
}
