import { css, createGlobalStyle } from 'styled-components'

import * as colors from './colors'

const selection = (color: string) => css`
  background: ${color};
  color: white;
`

// tslint:disable
export const BaseStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Inconsolata');
  @import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,600');
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

  *, *:before, *:after {
    box-sizing: border-box;
  }

  ::-moz-selection {
    ${selection(colors.link)}
  }

  ::selection {
    ${selection(colors.link)}
  }

  .icon-link {
    display: none;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.62;
    letter-spacing: -0.005em;
    background: white;
  }

  body {
    color: transparent;
  }

  body > *, #root {
    color: ${colors.text};
  }

  html, body, #root {
    height: 100vh;
    min-height: 100vh;
  }

  body.with-overlay,
  body.with-overlay #root {
    overflow: hidden;
  }

  a, a:visited, a:active {
    text-decoration: none;
    color: ${colors.link};
  }

  a:hover {
    color: ${colors.link};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: color 9999s ease-out, background-color 9999s ease-out;
    transition-delay: 9999s;
  }

  input:required,
  input:invalid {
    box-shadow: none;
  }

  button:focus {
    outline: none !important;
  }

  select {
    color: ${colors.text};
  }

  pre, code {
    font-family: Consolas;
  }

  table {
    border-collapse: collapse;
    margin-bottom: 25px;
  }

  table tr {

  }

  table tr:nth-of-type(2n) td {
    background-color: #f5f5f5;
  }

  table tr td {
    vertical-align: top;
    padding: 2px 8px;
    background-color: #fbfbfb;
  }

  blockquote {
    padding: 10px 30px 10px 30px;
    margin: 30px 0;
    border-radius: 3px;
    border-left: 4px solid #38D3EC;
    background: #EFF2F7;
    color: #8492A6;
  }

  table tr td blockquote {
    margin: 10px 0;
    padding: 5px 20px 5px 20px;
  }
  
  
    blockquote2 {
    padding: 10px 30px 10px 30px;
    margin: 30px 0;
    border-radius: 3px;
    border-left: 4px solid rgb(255 98 99);
    background: rgb(255 243 243);
    color: rgb(170 47 47);
  }

  table tr td blockquote2 {
    margin: 10px 0;
    padding: 5px 20px 5px 20px;
  }

  a.color.blue,
  p.color.blue,
  h1.color.blue,
  h2.color.blue,
  h3.color.blue,
  h4.color.blue,
  h5.color.blue,
  h6.color.blue,
  span.color.blue {
    color: #2288cc;
  }
  a.color.blue-light,
  p.color.blue-light,
  h1.color.blue-light,
  h2.color.blue-light,
  h3.color.blue-light,
  h4.color.blue-light,
  h5.color.blue-light,
  h6.color.blue-light,
  span.color.blue-light {
    color: #48cacc;
  }
  a.color.purple,
  p.color.purple,
  h1.color.purple,
  h2.color.purple,
  h3.color.purple,
  h4.color.purple,
  h5.color.purple,
  h6.color.purple,
  span.color.purple {
    color: #8d3deb;
  }
  a.color.green,
  p.color.green,
  h1.color.green,
  h2.color.green,
  h3.color.green,
  h4.color.green,
  h5.color.green,
  h6.color.green,
  span.color.green {
    color: #44bb55;
  }
  a.color.orange,
  p.color.orange,
  h1.color.orange,
  h2.color.orange,
  h3.color.orange,
  h4.color.orange,
  h5.color.orange,
  h6.color.orange,
  span.color.orange {
    color: #FFA500;
  }
  a.color.red,
  p.color.red,
  h1.color.red,
  h2.color.red,
  h3.color.red,
  h4.color.red,
  h5.color.red,
  h6.color.red,
  span.color.red {
    color: #ff3625;
  }
  a.color.grey,
  p.color.grey,
  h1.color.grey,
  h2.color.grey,
  h3.color.grey,
  h4.color.grey,
  h5.color.grey,
  h6.color.grey,
  span.color.grey {
    color: #99a3b1;
  }

  .mark.bold {
    font-weight: bold;
  }

  /* Tables */

  table {}

  table tr {}

  table tr td{ 
  }

  table tr td:nth-last-child(1){
    /* row experiment */
    color: #030303;
  }

  table tr td:nth-last-child(2){
    /* row experiment */
    color: #020202;
  }

  table tr td:nth-last-child(3){
    /* row experiment */
    color: #010101;
  }

  a.active {
    color: #4263F7 !important;
  }

  #root + div {
    position: fixed !important;
  }
`
