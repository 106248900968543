export function highLight() {
  var body = document.body,
    html = document.documentElement;

  var height = Math.max( body.scrollHeight, body.offsetHeight,
    html.clientHeight, html.scrollHeight, html.offsetHeight );

  const links = document.querySelectorAll('#submenu a');
  for(let i = 0; i < links.length; i++){
    links[i].classList.remove('active');
  }

  const EndCycle = {};

  try {
    [...document.anchors].filter( item => item.name.split('-')[0].length < 3 ).reverse().forEach(item => {
      if ((height - window.pageYOffset) < (height - item.offsetParent.offsetTop + 100)) {
        const link = window.location.pathname + '#' + item.name;
        const linkTag = document.querySelector('a[href="' + link + '"]');
        linkTag.classList.add('active');
        throw EndCycle;
      }
    })
  } catch (e){
    // do nothing
  }
}
