import * as React from 'react'
import { useCallback, useContext, useEffect } from 'react'
import { Fragment, SFC } from 'react'
import { Entry, Link as BaseLink, useDocs, useMenus } from 'docz'
import { useWindowSize } from 'react-use'
import styled, { css } from 'styled-components'

import { breakpoints } from '@styles/responsive'
import { mainContext } from '../Main'
import { TOPBAR_LINKS, IconLink } from '../Topbar'

interface WrapperProps {
  opened: boolean
  mobile: boolean
}

const toggle = (p: WrapperProps) => {
  return !p.opened && p.mobile ? '-100%' : '0'
}

const SidebarWrapper = styled.div<WrapperProps>`
  width: 280px;
  position: fixed;
  z-index: 4;
  min-width: 280px;
  height: calc(100% - 60px);
  padding: 20px 20px 20px 0;
  margin-right: 60px;
  overflow: auto;
  border-right: 1px solid ${p => p.theme.colors.grayLight};
  background: #fff;
  transition: transform 0.2s, background 0.3s;
  transform: translateX(${toggle});

  @media (max-width: ${breakpoints.mobile}px) {
    height: 100%;
  }

  ${p =>
    p.mobile &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      overflow: auto;
      z-index: 9999;
      padding: 30px;
    `};

  @media print {
    display: none
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${p =>
    p.theme.mq({
      position: ['relative', 'relative', 'relative', 'relative'],
      top: ['0px', '0px', '0', '0'],
    })};
`

const Link = styled(BaseLink)`
  font-size: 16px;
  font-weight: 400;
  padding: 2px 0;

  &,
  &:visited {
    color: #000;
  }

  &.active,
  &:hover {
    color: ${p => p.theme.colors.purple};
  }
`

const SmallLink = styled(BaseLink)`
  font-size: 14px;
  padding: 0 0 5px 10px;

  padding-left: ${p => (p.depth === 3 ? '20px' : '10px')};

  &,
  &.active,
  &:visited {
    color: #000000;
  }

  &:hover {
    color: ${p => p.theme.colors.purple};
  }
`

const Submenu = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
`

const MenuGroup = styled.h2`
  margin: 30px 0 5px;
  font-size: 12px;
  opacity: 1;
  text-transform: uppercase;

  &:first-child {
    margin-top: 0;
  }
`

interface OpenProps {
  opened: boolean
}

const ToggleBackground = styled.div`
  content: '';
  display: ${(p: OpenProps) => (!p.opened ? 'none' : 'block')};
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  z-index: 98;
`

interface MenuProps {
  doc: Entry
  active: boolean
  onClick: React.MouseEventHandler
}

const Menu: SFC<MenuProps> = ({ doc, active, onClick }) => {
  const headings = doc.headings.filter(
    heading => heading.depth > 1 && heading.depth < 4
  )

  const clickSubMenu = useCallback((e: React.MouseEventHandler) => {
    e.preventDefault();
    const targetUri = e.target.href.split('#');
    if(targetUri[0] !== document.location.href.split('#')[0]) {
      document.location.href = e.target.href;
    }

    const anchor = decodeURI(targetUri[1]);

    if(anchor) {
      let element_to_scroll_to = document.getElementById(anchor);

      if (!element_to_scroll_to) {
        element_to_scroll_to = document.querySelector('a[name="'+anchor+'"]');
      }

      if (element_to_scroll_to) {
        // element_to_scroll_to.scrollIntoView();
        const y = element_to_scroll_to.getBoundingClientRect().top + window.scrollY - 60;
        window.scrollTo(0, y);


        history.replaceState(null, null, `#${anchor}`);
      }
    }

    onClick(e);
  },[onClick]);

  return (
    <Fragment>
      <Link to={doc.route} onClick={onClick}>
        {doc.name}
      </Link>
      {active && headings.length > 0 && (
        <Submenu id="submenu">
          {headings.map((heading) => {
            let slug = heading.slug;
            const slugSplit = heading.slug.split('-')[0] + '-';
            const slugSplitArr = heading.slug.split(slugSplit);
            const slugSecond = slugSplitArr[slugSplitArr.length-1];

            if(slugSecond){
              slug = slugSplit + slugSecond;
            }

            const menuLabel = heading.value.replace(/<\/?[^>]+(>|$)/g, "");

            return (
            <SmallLink
              depth={heading.depth}
              key={heading.slug}
              to={`${doc.route}#${slug}`}
              onClick={clickSubMenu}
            >
              {
                heading.depth === 3 ? menuLabel.replace(/\d\.\d\.\d/g,'') : menuLabel
              } {/* TODO re-use links in navigation */}
            </SmallLink>
          )})}
        </Submenu>
      )}
    </Fragment>
  )
}

interface TopbarMenuProps {
  onClick: React.MouseEventHandler
}

const TopbarMenu: React.SFC<TopbarMenuProps> = ({ onClick }) => {
  return (
    <React.Fragment>
      {TOPBAR_LINKS.map(({ id, children, ...props }) => {
        const Component = props.to ? Link : IconLink
        return (
          <Component key={id} {...props} onClick={onClick}>
            {children}
          </Component>
        )
      })}
    </React.Fragment>
  )
}

interface SidebarProps {
  menu: string
  pathname?: string
  mobile?: boolean
}

export const Sidebar: SFC<SidebarProps> = ({
  menu: current,
  pathname,
  mobile,
}) => {
  const docs = useDocs()
  const { width } = useWindowSize()
  const { showing, setShowing } = useContext(mainContext)

  const menus = useMenus()
  const isDesktop = width > breakpoints.tablet

  const toggle = useCallback(() => {
    setShowing((s: any) => !s)
  }, [])

  const handleSidebarToggle = (ev: React.SyntheticEvent<any>) => {
    if (isDesktop) return
    toggle && toggle()
  }

  return (
    <React.Fragment>
      <SidebarWrapper opened={showing} mobile={Boolean(mobile)}>
        <Wrapper>
          {mobile && <TopbarMenu onClick={handleSidebarToggle} />}
          {menus &&
            menus.map(({ id, name, menu }) => {
              if (!menu) return null
              return (
                <React.Fragment key={id}>
                  <MenuGroup>{name}</MenuGroup>
                  {menu.map(item => {
                    const doc = docs && docs.find(doc => doc.name === item.name);
                    if (!doc) return null
                    return (
                      <Menu
                        key={doc.id}
                        doc={doc}
                        active={true}
                        // active={Boolean(
                        //   pathname && (pathname === doc.route)
                        // )}
                        onClick={handleSidebarToggle}
                      />
                    )
                  })}
                </React.Fragment>
              )
            })}
        </Wrapper>
      </SidebarWrapper>
      {!isDesktop && (
        <ToggleBackground opened={showing} onClick={handleSidebarToggle} />
      )}
    </React.Fragment>
  )
}
