import * as React from 'react'
import { SFC, useEffect, useState, createContext } from 'react'
import { useWindowSize } from 'react-use'
import { breakpoints } from '@styles/responsive'
import { highLight } from "./utils";
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

export const mainContext = createContext<any>(null)

export const Main: SFC = ({ children }) => {
  const [showing, setShowing] = useState(false)
  const { width } = useWindowSize()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const body = document.querySelector('body')
      const method = showing ? 'add' : 'remove'
      body && body.classList[method]('with-overlay')
    }
  }, [showing])

  useEffect(() => {
    if (width > breakpoints.tablet) {
      const body = document.querySelector('body')
      body && body.classList.remove('with-overlay')
      setShowing(false)
    }
  }, [width])

  useEffect(() => {
    document.addEventListener('scroll', () => highLight());
    highLight();
  })

  useEffect(() => {
    document.addEventListener('click', function(event) {
      const {
        target
      } = event;
      const link = target.closest('a');
      if(link && link.tagName === 'A' && link.pathname === window.location.pathname && link.hash){
        event.preventDefault();

        const anchor = link.hash.replace('#', '');
        let element_to_scroll_to = document.getElementById(anchor);

        if (!element_to_scroll_to) {
          element_to_scroll_to = document.querySelector('a[name="'+anchor+'"]');
        }

        if (element_to_scroll_to) {
          const y = element_to_scroll_to.getBoundingClientRect().top + window.scrollY - 60;
          window.scrollTo(0, y);
        }
      }

      if(link && link.tagName === 'A' && link.pathname === window.location.pathname && !link.hash){
        event.preventDefault();

        window.scrollTo(0, 0);
      }
    });

    setTimeout( () => {
      if(window.location.hash) {
        const anchor = decodeURI(window.location.hash).replace('#', '');
        let element_to_scroll_to = document.getElementById(anchor);

        if (!element_to_scroll_to) {
          element_to_scroll_to = document.querySelector('a[name="'+anchor+'"]');
        }

        if (element_to_scroll_to) {
          const y = element_to_scroll_to.getBoundingClientRect().top + window.scrollY - 60;
          window.scrollTo(0, y);
        }
      }
    }, 500 );
  }, [])

  return (
    <mainContext.Provider value={{ showing, setShowing }}>
      <Wrapper>{children}</Wrapper>
    </mainContext.Provider>
  )
}
