import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer'

// TODO навести порядок

export const Code = ({ children, className: outerClassName }) => {
  const [language] = outerClassName
    ? outerClassName.replace(/language-/, '').split(' ')
    : ['text']

  const hasChildren = children && children.props;
  return (<>
    {hasChildren ? <Highlight {...defaultProps} code={children.props.children} language={children.props.className.replace(/language-/, '').split(' ')}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => {
        tokens.pop(); // удаляем последнюю пустую строку
        return (
          <pre className={className} style={style}>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}</pre>
        )}
      }
    </Highlight> : <Highlight {...defaultProps} code={children} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => {
        tokens.pop(); // удаляем последнюю пустую строку
        return (
        <pre className={className} style={style}>
        {tokens.map((line, i) => (
          <div {...getLineProps({ line, key: i })}>
            {line.map((token, key) => (
              <span {...getTokenProps({ token, key })} />
            ))}
          </div>
        ))}</pre>
      )}
      }
    </Highlight>}
    </>
  )
}
